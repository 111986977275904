import * as React from 'react'
import Layout from '../components/layout'
import RoomCardList from '../components/roomCardList'

const OverviewPage = () => {
    return (
        <Layout pageTitle="Overzicht" titleLarge="Ruimtes" titleSmall="Ontdek onze">
            <section className="featured-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center">
                                <h3 className="">Onze Ruimtes</h3>
                                <span className="text-uppercase">Uw inspiratiebron</span>
                            </div>
                        </div>
                    </div>
                    <RoomCardList></RoomCardList>
                </div>
            </section>
        </Layout>
    )
  }
  
  export default OverviewPage
